import * as React from "react";
import { TextField, MenuItem, Box } from "@mui/material";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import {
  citizenship,
  channels,
  gender,
  marital,
  medicaid,
  daycare,
} from "../../../helper/formSelectOptions";
import dayjs from "dayjs";

var utc = require("dayjs/plugin/utc");
var timezone = require("dayjs/plugin/timezone"); // dependent on utc plugin
dayjs.extend(utc);
dayjs.extend(timezone);
const tzToDisplay = "America/New_York";

export default function ClientFormField({ client, disabled }) {
  return (
    <>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          disabled={disabled}
          id="firstName"
          label="First Name"
          name="firstName"
          defaultValue={client.first_name}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
        <TextField
          disabled={disabled}
          id="lastName"
          label="Last Name"
          name="lastName"
          defaultValue={client.last_name}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={disabled}
            id="dob"
            label="Date of Birth"
            name="dob"
            slotProps={{ textField: { id: "dob", error: false } }}
            defaultValue={dayjs.tz(client.dob, tzToDisplay)}
            sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          disabled={disabled}
          select
          id="gender"
          label="Gender"
          name="gender"
          defaultValue={client.gender}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        >
          {gender.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled={disabled}
          select
          id="marital"
          label="Marital Status"
          name="marital"
          defaultValue={client.marital}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        >
          {marital.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          disabled={disabled}
          select
          id="channel"
          label="How did you hear about us?"
          name="channel"
          defaultValue={client.from_channel}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        >
          {channels.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          disabled={disabled}
          id="contact"
          label="Contact Person"
          name="contact"
          defaultValue={client.contact}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
        <TextField
          disabled={disabled}
          id="relationship"
          label="Relationship"
          name="relationship"
          defaultValue={client.relationship}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
        <LocalizationProvider dateAdapter={AdapterDayjs}>
          <DatePicker
            disabled={disabled}
            id="initialContactDate"
            label="Initial Contact Date"
            name="initialContactDate"
            slotProps={{ textField: { id: "icd" } }}
            defaultValue={dayjs.tz(client.initial_contact_date, tzToDisplay)}
            sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
          />
        </LocalizationProvider>
      </Box>
      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          disabled={disabled}
          id="phone"
          label="Phone Number"
          name="phone"
          defaultValue={client.phone}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
        <TextField
          disabled={disabled}
          id="email"
          label="Email Address"
          name="email"
          defaultValue={client.email}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
        <TextField
          disabled={disabled}
          id="address"
          label="Address"
          name="address"
          defaultValue={client.address}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        />
      </Box>

      <Box sx={{ display: "flex", justifyContent: "space-between" }}>
        <TextField
          select
          id="citizenship"
          disabled={disabled}
          label="Citizenship"
          name="citizenship"
          defaultValue={client.citizenship}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        >
          {citizenship.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          id="medicaid"
          disabled={disabled}
          label="Medicaid Category"
          name="medicaid"
          defaultValue={client.medicaid}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        >
          {medicaid.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          select
          id="daycare"
          disabled={disabled}
          label="Daycare Eligibility"
          name="daycare"
          defaultValue={client.daycare}
          sx={{ width: "30%", marginTop: "16px", marginBottom: "8px" }}
        >
          {daycare.map((option) => (
            <MenuItem key={option.value} value={option.value}>
              {option.label}
            </MenuItem>
          ))}
        </TextField>
      </Box>

      <TextField
        disabled={disabled}
        fullWidth
        id="physician"
        label="Physician"
        name="physician"
        defaultValue={client.physician}
        sx={{ marginTop: "16px", marginBottom: "8px" }}
      />
      <TextField
        disabled={disabled}
        fullWidth
        id="diagnosis"
        label="Diagnosis"
        name="diagnosis"
        defaultValue={client.diagnosis}
        sx={{ marginTop: "16px", marginBottom: "8px" }}
      />
      <TextField
        disabled={disabled}
        fullWidth
        id="notes"
        label="Notes"
        name="notes"
        defaultValue={client.notes}
        sx={{ marginTop: "16px", marginBottom: "8px" }}
      />
    </>
  );
}
